import { Box, Flex } from 'grid-styled'
import { graphql, Link } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Map from '../components/Map/Map'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import Button from '../components/Button'
import styled from 'styled-components'
import Card from '../components/Card'
import { yPad } from '../utils/theme'

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>On Site Quote - Just Patios</title>
      <meta
        name="description"
        content="Request an on site quote for your patio, deck, carport or room enclosure from Just Patios. Experienced builders. Call us for a cost effective free quote."
      />
    </Helmet>
    <Contact
      id="form"
      header="<h2>On Site <i>Quote</i></h2>"
      content={`Please complete the form below to arrange a free on site quote for your <a href="/patio-builders-brisbane">new patio</a>. We will call you as soon as possible to organise a convenient time to visit.`}
      style={{ paddingTop: '10rem', textAlign: 'left' }}
      noBorder={true}
    />
  </Layout>
)
